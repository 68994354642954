/* eslint-disable no-useless-catch */
/* eslint-disable class-methods-use-this */
import { amgApi } from '@/service/axios';

class PendingPensionFundService {
  async updatePensionFund(body) {
    try {
      const { data, status } = await amgApi.post('/profile/update-pension-fund', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getPendingPensionFundEmployees(body) {
    try {
      const data = await amgApi.post('/management/employees/get-pending-pension-fund-employees', body);
      return data;
    } catch (error) {
      console.log('Something went wrong on getPendingPensionFundEmployees:', error);
      throw error;
    }
  }
}
export default new PendingPensionFundService();
