export default [
  {
    type: 'datepicker',
    margin: true,
    showLabel: true,
    label: 'From',
    placeholder: 'Date',
    class: 'font-small-3',
    model: null,
    locale: 'en',
    dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
    cols: 6,
    visible: true,
  },
  {
    type: 'datepicker',
    margin: true,
    showLabel: true,
    label: 'To',
    placeholder: 'Date',
    class: 'font-small-3',
    model: null,
    locale: 'en',
    dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
    cols: 6,
    visible: true,
  },
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Status',
    model: null,
    options: [
      {
        id: 1,
        name: 'Pending',
      },
      {
        id: 2,
        name: 'Affiliated',
      },
      {
        id: 3,
        name: 'Canceled',
      },
    ],
    reduce: 'id',
    selectText: 'name',
    cols: 12,
    visible: true,
    placeholder: '--Select option--',
  },
];
