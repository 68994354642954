<template>
  <div>
    <b-card no-body>
      <filter-slot
        :filter="filters"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reload="$refs['pendingPensionFundList'].refresh()"
      >
        <b-table
          id="pendingPensionFundList"
          slot="table"
          ref="pendingPensionFundList"
          show-empty
          sticky-header="70vh"
          :busy.sync="isBusy"
          :items="getData"
          :fields="fields"
          :per-page="paginate.perPage"
          :current-page="paginate.currentPage"
          :filter="filters"
          table-class="text-nowrap"
          responsive="sm"
          no-border-collapse
          no-provider-filtering

        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(photo)="{ item }">
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-start
              "
            >
              <b-avatar
                :src="item.url"
                rounded="sm"
              />
            </div>
          </template>
          <template #cell(number)="{ item }">
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-start
              "
            >
              <router-link
                target="_blank"
                :class="[textLink]"
                :to="{
                  name: $route.meta.employeeRoute,
                  params: { id: item.employee_id },
                }"
              >
                {{ item.number }}
              </router-link>
            </div>
          </template>
          <template #cell(type)="{item}">
            <b-badge :variant="item.old_pension_fund?'secondary':'success'">
              {{ item.old_pension_fund ? 'CHANGE':'NEW AFFILIATION' }}
            </b-badge>
          </template>
          <template #cell(old_pension_fund)="{item}">
            <strong :class="item.old_pension_fund_id ? colorPension[item.old_pension_fund_id]:''">{{ item.old_pension_fund || '-' }}</strong>
          </template>
          <template #cell(new_pension_fund)="{item}">
            <strong :class="colorPension[item.new_pension_fund_id]">{{ item.new_pension_fund || '-' }}</strong>
          </template>
          <template #cell(status)="{item}">
            <b-badge :variant="bgColor[item.status_id-1]">
              {{ item.status.toUpperCase() || '-' }}
            </b-badge>
          </template>
          <template #cell(actions)="{item}">
            <template v-if="item.status_id === 1">
              <feather-icon
                v-b-tooltip.hover.v-success
                v-b-tooltip.hover.bottom
                icon="ThumbsUpIcon"
                class="text-success mr-1 hover-me cursor-pointer"
                size="18"
                title="Affiliate"
                @click="assignCUSPP(item)"
              />
              <feather-icon
                v-b-tooltip.hover.v-danger
                v-b-tooltip.hover.bottom
                icon="ThumbsDownIcon"
                class="text-danger mr-1 hover-me cursor-pointer"
                size="18"
                title="Canceled"
                @click="updatePensionFund(item,3)"
              />
            </template>
            <feather-icon
              v-else-if="item.status_id === 2"
              v-b-tooltip.hover.v-success
              v-b-tooltip.hover.bottom
              icon="CheckCircleIcon"
              class="text-success mr-1"
              size="18"
              title="Affiliated"
            />
            <feather-icon
              v-else-if="item.status_id === 3"
              v-b-tooltip.hover.v-danger
              v-b-tooltip.hover.bottom
              icon="XIcon"
              class="text-danger mr-1"
              size="18"
              title="Canceled"
            />
          </template>
        </b-table>
      </filter-slot>
    </b-card>
    <!--modal CUSPP-->
    <b-modal
      v-model="paramsModalCUSPP.show"
      size="default"
      centered
      @hidden="paramsModalCUSPP.show = false"
    >
      <template #modal-title>
        <h5 class="text-uppercase">
          CUSPP
        </h5>
      </template>
      <b-card
        class="shadow-none p-0"
        no-body
      >
        <validation-observer ref="formCUSPP">
          <b-row class="p-1">
            <b-col cols="12">
              <b-form-group label="Employee">
                <b-form-input
                  placeholder="Employee"
                  :value="`${paramsModalCUSPP.item.first_name} ${paramsModalCUSPP.item.last_name}`"
                  class="text-capitalize"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <validation-provider
                v-slot="{errors}"
                name="codigo"
                rules="required|max:20"
              >
                <b-form-group label="Codigo CUSPP">
                  <b-form-input
                    v-model="paramsModalCUSPP.item.cuspp"
                    placeholder="Codigo CUSPP"
                    :class="errors[0]?'border-danger':''"
                  />
                  <span
                    v-if="errors[0]"
                    class="text-danger"
                  >{{ errors[0] }}</span>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </b-card>
      <template #modal-footer>
        <div>
          <b-button
            variant="secondary"
            class="mr-1"
            @click="paramsModalCUSPP.item.show = false"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            @click="updatePensionFund(paramsModalCUSPP.item,2)"
          >
            Save
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import filters from '@/views/management/views/employees/employees-module/pending-pension-fund/data/filters.data';
import fields from '@/views/management/views/employees/employees-module/pending-pension-fund/data/fields.data';
import PendingPensionFundService from '@/views/management/views/employees/employees-module/pending-pension-fund/service/pendingPensionFund.service';

export default {
  data() {
    return {
      filters,
      totalRows: 0,
      startPage: 0,
      toPage: 0,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search by name...',
        model: '',
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      isBusy: false,
      fields,
      colorPension: [
        'text-secondary',
        'text-primary',
        'text-warning',
        'text-info',
        'text-success',
      ],
      bgColor: [
        'warning',
        'success',
        'danger',
      ],
      paramsModalCUSPP: {
        show: false,
        item: {},
        cuspp: null,
      },
      keyGrid: 0,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  methods: {
    async getData() {
      try {

        const params = {
          perpage: this.paginate.perPage,
          npage: this.paginate.currentPage,
          search: this.filterPrincipal.model,
          from: this.filters[0] ? this.filters[0].model : null,
          to: this.filters[1] ? this.filters[1].model : null,
          status: this.filters[2] ? this.filters[2].model : null,
        };
        const { data } = await PendingPensionFundService.getPendingPensionFundEmployees(params);
        this.totalRows = data.total || 0;
        this.startPage = data.from || 0;
        this.toPage = data.to || 0;

        return data.data;
      } catch (error) {
        this.showErrorSwal();
        console.log('error: ', error);
      }
      return [];
    },
    async updatePensionFund(item, statusPension) {
      try {
        const validFormCUSPP = statusPension === 2 ? await this.$refs?.formCUSPP.validate() : true;
        if (!validFormCUSPP) return;

        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;

        const params = {
          current_pension_fund: statusPension === 2 ? item.new_pension_fund_id : null,
          old_pension_fund: null,
          new_pension_fund: null,
          status: statusPension,
          user_id: item.id_user,
          created_by: this.currentUser.user_id,
          employee: `${item.first_name} ${item.last_name}`,
        };
        const { status } = await PendingPensionFundService.updatePensionFund(params);
        this.removePreloader();
        if (status === 200) {
          this.showSuccessSwal();
          this.paramsModalCUSPP.show = false;
          this.$refs.pendingPensionFundList.refresh();
        }
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal();
        console.log('error', error);
      }
    },
    assignCUSPP(item) {
      this.paramsModalCUSPP.show = true;
      this.paramsModalCUSPP.item = item;
    },
  },
};
</script>
