export default [
  {
    key: 'photo',
    sortable: false,
    label: 'Photo',
  },
  {
    key: 'number',
    label: 'Number',
  },
  {
    key: 'pid',
    label: 'ID',
  },
  {
    key: 'first_name',
    label: 'First Name',
  },
  {
    key: 'last_name',
    label: 'Last Name',
  },
  {
    key: 'department',
    label: 'Department',
  },
  {
    key: 'old_pension_fund',
    label: 'old pension fund',
  },
  {
    key: 'new_pension_fund',
    label: 'new pension fund',
  },
  {
    key: 'type',
    label: 'Type',
  },
  {
    key: 'status',
    label: 'status',
  },
  {
    key: 'actions',
    label: 'Actions',
  },
];
